import * as React from 'react';
import { CircularProgress, createTheme, Paper, Stack, ThemeProvider } from '@mui/material';
import { enUS, GridCallbackDetails, GridColumnOrderChangeParams, GridColumnVisibilityModel, GridEventListener, GridFilterModel, GridSortModel, GridToolbar } from '@mui/x-data-grid';
import styles from './style.module.css';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import useForecastContext from '../../../hooks/ContextHooks/useForecastContext';

import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { initialState, setFilterModel, setSortModel, setTablePreference, userState } from '../../../stores/Slices/userSlice';
import { tablePrefrencesColumnsMapping } from './components/CustomizationButton/CustomizationButton';
import { gql } from '@apollo/client';
import { secondaryClient } from '../../../api/apollo';
import { Dictionary } from '@reduxjs/toolkit';
import { swap } from '../../../utils/dataTransformation';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useUserSettings from '../../../hooks/ApiHooks/useUserSettings';

export interface IFieldTableComponentProps {
}


const theme = createTheme(
  {
    palette: {
      primary: { main: "#0958FF" },
    },
  },
  enUS,
);

const getColumnVisibilityModel =
  (tablePrefrence: userState["tablePreference"]): GridColumnVisibilityModel => {
    const columnVisibility: GridColumnVisibilityModel = {};
    (tablePrefrence["ProbeTable"] || initialState.tablePreference["ProbeTable"]).forEach((a) => {
      columnVisibility[tablePrefrencesColumnsMapping[a.name]] = a.checked === 1;
    });
    return columnVisibility;
  };

export default function FieldTableComponent(props: IFieldTableComponentProps) {

  const apiRef = useGridApiRef();
  const { row, columns, loading, setColumns } = useForecastContext();
  const [pageSize, setPageSize] = useState<number>(50);
  const dispatch = useAppDispatch();
  const { refreshReduxFromGraphQL, loading: SettingsLoading } = useUserSettings();
  const tablePrefrence = useAppSelector(state => state.user.tablePreference);
  const sortModel = useAppSelector(state => state.user.sortModel);
  const [emptySortModel, setEmptySortModel] = React.useState(true);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(getColumnVisibilityModel(tablePrefrence))

  const handleColumnVisibilityModelChange = useCallback((model: GridColumnVisibilityModel, details: GridCallbackDetails<any>) => {
    let updatedModel: GridColumnVisibilityModel = {};

    if (Object.keys(model).length > 0) {
      updatedModel = model;
    }
    else {
      Object.keys(columnVisibilityModel).forEach((key: string | number) => {
        updatedModel[key] = true;
      });
    }

    setColumnVisibilityModel(updatedModel);

    const updatedtablePreference: userState["tablePreference"] = {
      ...tablePrefrence,
      "ProbeTable": (
        apiRef.current.getAllColumns()
          .map(a => (
            {
              name: columnsTablePrefrencesMapping[a.field],
              checked: updatedModel[a.field] ? 1 : 0
            }
          )
          )
      )
    }

    dispatch(setTablePreference(updatedtablePreference));

    UpdateTablePrefrenceInDB(updatedtablePreference)

  }, [apiRef, tablePrefrence])

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (emptySortModel) {setEmptySortModel(false); return}
    const updatedSortModel = {
      ...sortModel,
      "ProbeTable": newModel,
    }

    // dispatch(setSortModel())
    dispatch(setSortModel(updatedSortModel));

    UpdateSortModelInDB(updatedSortModel);
  };

  const filterModel = useAppSelector(state => state.user.filterModel);

  const [isFilterModelEmpty, setIsFilterModelEmpty] = useState(true);
  const handleFilterModelChange = (newModel: GridFilterModel) => {
    if (isFilterModelEmpty) {return setIsFilterModelEmpty(false);}
    if (newModel.items.length === 0) {
      const updatedFilterModel = {
        ...filterModel,
        "ProbeTable": {
          items: [
            {
              columnField: 'LocationDescription',
              operatorValue: 'contains'
            }
          ]
        },
      }
      dispatch(setFilterModel(updatedFilterModel));
      UpdateFilterModelInDB(updatedFilterModel)
    } else {
      const updatedFilterModel = {
        ...filterModel,
        "ProbeTable": newModel,
      }
      dispatch(setFilterModel(updatedFilterModel));
      UpdateFilterModelInDB(updatedFilterModel)
    };
  }

  useEffect(() => {
    setColumnVisibilityModel(getColumnVisibilityModel(tablePrefrence));
  }, [tablePrefrence["ProbeTable"]]);

  /**
   * Temp fix for the default sort as this is still an ongoing issue for MUI grid.
   * Discussions:
   * https://github.com/mui/mui-x/issues/6206
   */
  useEffect(() => {
    if (apiRef.current) {
      if (columns.length > 0) {
        apiRef.current.setSortModel(sortModel ? sortModel["ProbeTable"] : initialState.sortModel["ProbeTable"]);
      }
    }
  }, [apiRef, columns])

  const reorderingRef = useRef<{ isDragging: boolean; oldIndex: null | number; targetIndex: null | number; }>({
    isDragging: false,
    oldIndex: null,
    targetIndex: null
  });

  const columnsTablePrefrencesMapping: typeof tablePrefrencesColumnsMapping = useMemo(() => swap(tablePrefrencesColumnsMapping), [tablePrefrencesColumnsMapping])

  useEffect(() => {
    const handleColumnHeaderDragEnd: GridEventListener<"columnHeaderDragEnd"> =
      (params) => {

        // https://codesandbox.io/s/eager-shamir-5grln1?file=/demo.tsx:1315-1371

        const updatedtablePreference = {
          ...tablePrefrence,
          "ProbeTable": apiRef.current.getAllColumns()
            .map(a => columnsTablePrefrencesMapping[a.field])
            .map(a => ({ name: a, checked: tablePrefrence["ProbeTable"][tablePrefrence["ProbeTable"].findIndex(b => b.name === a)].checked }))
        }

        dispatch(setTablePreference(updatedtablePreference));
        setColumns(apiRef.current.getAllColumns());

        UpdateTablePrefrenceInDB(updatedtablePreference);

        reorderingRef.current = {
          isDragging: false,
          oldIndex: null,
          targetIndex: null
        };
      };

    // The `subscribeEvent` method will automatically unsubscribe in the cleanup function of the `useEffect`.
    return apiRef.current.subscribeEvent(
      "columnHeaderDragEnd",
      handleColumnHeaderDragEnd
    );
  }, [apiRef, tablePrefrence]);

  function handleColumnOrderChange(params: GridColumnOrderChangeParams, event: any, details: any): void {
    // https://github.com/mui/mui-x/issues/6126
    // ^^ they are also saving the column state to the db

    reorderingRef.current = reorderingRef.current.isDragging
      ? { ...reorderingRef.current, targetIndex: params.targetIndex }
      : {
        isDragging: true,
        oldIndex: params.oldIndex,
        targetIndex: params.targetIndex
      };
  }

  useEffect(() => {
    refreshReduxFromGraphQL()
  }, []);

  return (
    <Paper elevation={1}>
      <div className={styles.grid}>
        <ThemeProvider theme={theme}>
          <DataGridPro
            apiRef={apiRef}

            sx={{
              border: 0,
              '& .super-app.overOneDay': {
                color: '#FF0000',
              },
            }}
            className={styles.DataGrid}
            experimentalFeatures={{ newEditingApi: true }}
            rows={row}
            columns={columns}
            onColumnOrderChange={handleColumnOrderChange}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
            density='compact'
            getRowId={(row: any) => row.LocationID}
            pagination
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50]}

            filterModel={filterModel ? filterModel["ProbeTable"] : initialState.filterModel["ProbeTable"]}
            onFilterModelChange={(model) => handleFilterModelChange(model)}
            sortModel={sortModel ? sortModel["ProbeTable"] : initialState.sortModel["ProbeTable"]}
            onSortModelChange={(model)=> handleSortModelChange(model)}
            // rowCount={count}
            // paginationMode="server"
            // onPageChange={(newPage) => {
            //     setLoading(true);
            //     setPage(newPage);
            // }}
            //disabled for client side data operations
            // sortingMode="server"
            // onSortModelChange={(sortModel: GridSortModel) => {
            //     const tmp: string = Object.values(sortModel[0]).join(' ');
            //     setLoading(true);
            //     setSort(tmp);
            // }}
            // //disabled for client side data operations
            // filterMode="server"
            // onFilterModelChange={(filterModel: GridFilterModel) => {
            //     /* console.log('filter is set: ', filterModel); */
            // }}
            // //disabled for client side data operations
            components={{
              Toolbar: GridToolbar,
              LoadingOverlay: () => <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'baseline',
                marginTop: "20px"
              }}><CircularProgress /></div>,
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <span>No rows.</span>
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <span style={{fontSize: '1rem'}}>No results found.</span>
                </Stack>
              )
            }}

            loading={loading || SettingsLoading}
            // checkboxSelection={true}

            // initialState={{
              // filter: {
              //   filterModel: {
              //     items: [{
              //       columnField: "Active",
              //       operatorValue: "is",
              //       value: true
              //     } as GridFilterItem]
              //   }
              // }
              // columns: {
              //   columnVisibilityModel: getColumnVisibilityModel()
              // },
              //doens't work when column is not available when created, now using apiref to insert the sort model when Column becomes available
              // sorting: {
              //     sortModel: [{ field: "LocationDescription", sort: "asc" }],
              // },
            // }}

          />
        </ThemeProvider>
      </div>
    </Paper>
  );
}

// TODO: Move this somewhere sensible
export function UpdateTablePrefrenceInDB(updatedtablePreference: userState["tablePreference"]) {
  const SET_TABLE_PREFERENCE = gql(`
            mutation ($prefrences: String!) {
              UpdateUserSetting (
                input: {
                    tablePreference: $prefrences
                  authToken: ${localStorage.getItem("access_token")}
                }
                ) {
                  tablePreference
                }
              }`);

  secondaryClient.mutate({
    mutation: SET_TABLE_PREFERENCE,
    variables: { prefrences: JSON.stringify(updatedtablePreference) },
  })
    .catch((e) => console.error(e));
}

export function UpdateSortModelInDB(updatedtablePreference: userState["sortModel"]) {
  const SET_SORT_MODEL = gql(`
            mutation ($prefrences: String!) {
              UpdateUserSetting (
                input: {
                  sortModel: $prefrences
                  authToken: ${localStorage.getItem("access_token")}
                }
                ) {
                  sortModel
                }
              }`);

  secondaryClient.mutate({
    mutation: SET_SORT_MODEL,
    variables: { prefrences: JSON.stringify(updatedtablePreference) },
  })
    .catch((e) => console.error(e));
}

export function UpdateFilterModelInDB(updatedtablePreference: userState["filterModel"]) {
  const SET_FILTER_MODEL = gql(`
            mutation ($prefrences: String!) {
              UpdateUserSetting (
                input: {
                  filterModel: $prefrences
                  authToken: ${localStorage.getItem("access_token")}
                }
                ) {
                  filterModel
                }
              }`);

  secondaryClient.mutate({
    mutation: SET_FILTER_MODEL,
    variables: { prefrences: JSON.stringify(updatedtablePreference) },
  })
    .catch((e) => console.error(e));
}