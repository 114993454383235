import * as React from 'react';
import useFieldTableData from '../../hooks/useFieldTableData';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
export interface ForecastContextType {
    columns: any[],
    row: any[],
    loading: boolean,
    setColumns: React.Dispatch<React.SetStateAction<any[]>>,
}

export interface IForecastContextProviderProps {
    fetchedActiveOnly: boolean;
    status: string;
    children: React.ReactNode;
}

const ForecastContext = React.createContext<ForecastContextType>({
    columns: [],
    row: [],
    loading: false,
    setColumns: () => undefined,
});

function ForecastContextProvider(props: IForecastContextProviderProps) {

    const getAllUserDevices = useFieldTableData();
    const [row, setRow] = React.useState<any[]>([]);
    const [filteredRow, setFilteredRow] = React.useState<any[]>([]);
    const [columns, setColumns] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState(true);
    const { preferredFields }: { preferredFields: number[] } = useSelector((state: any) => state.user);

    const location = useLocation();

    React.useEffect(() => {
        if (!location.pathname.includes('fields')) {
            handleDataGridChange();
        }
    }, [location.pathname, props.fetchedActiveOnly])

    const handleDataGridChange = React.useCallback(async () => {
        setLoading(true);
        await getAllUserDevices(props.fetchedActiveOnly ? "Active eq true" : undefined).then((response: any) => {
            setColumns(response.columns);

            const preferredRows = response.row.filter(
                (_row: any) => preferredFields
                    .find((prefField: number) => _row.LocationID == prefField) !== undefined
            )
            setRow(preferredRows.length == 0 ? response.row : preferredRows);
        });
        setLoading(false);
    }, [getAllUserDevices, props.fetchedActiveOnly])

    React.useEffect(() => {
        switch (props.status) {
            case 'Active':
                setFilteredRow(row.filter((_row) => _row.Active === true));
                break;
            case 'Inactive':
                setFilteredRow(row.filter((_row) => _row.Active === false));
                break;
            case 'Active and inactive':
                setFilteredRow(row);
                break;
        }
    }, [row, props.status])

    return (
        <ForecastContext.Provider value={{ row: filteredRow, columns, loading, setColumns }}>
            {props.children}
        </ForecastContext.Provider>
    );
}

export { ForecastContext };
export default React.memo(ForecastContextProvider);