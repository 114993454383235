import { EChartsOption, color } from 'echarts';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { http } from '../../../../../api/http';
import FieldDetailBody from '../../../../../components/FieldDetailPaper/FieldDetailBody';
import FieldDetailHeader from '../../../../../components/FieldDetailPaper/FieldDetailHeader';
import FieldDetailPaper from '../../../../../components/FieldDetailPaper/FieldDetailPaper';
import GoannaButton from '../../../../../components/GoannaButton/GoannaButton';
import { ReactECharts } from '../../../../../components/ReactEcharts/ReactEcharts';
import useCanopyStressContext from '../../../../../hooks/ContextHooks/useCanopyStressContext';
import useFieldDetailContext from '../../../../../hooks/ContextHooks/useFieldDetailContext';
import { RootState } from '../../../../../stores/store';
import { useTooltipFormatterWithDateFormat, valueFormatter } from '../../../../../utils/Echarts';
import { dateFormatter, getUnitName, numericValueFormatter } from '../../../../../utils/numericConversions';

import ResetDialog from './ResetDialog';
import * as url from '../../../../../assets/images/MarkPoint/Line.png';
import styles from './style.module.css';
import { useTranslation } from 'react-i18next';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import moment from 'moment';
import { AEST_Zone, aestToLocationLocalTimeConverter } from '../../../../../utils/DateConvertor';
import { Alert } from '@mui/material';
import { useSnackbar } from 'notistack';
import { NOW } from '../../../../../context/FieldDetailsContext/FieldDetailsContext';
import { XAXisOption } from 'echarts/types/dist/shared';
import { useAppSelector } from '../../../../../stores/hooks';
import HelpIconButton from '../HelpIconButton/HelpIconButton';

export interface ICanopyStressProps {
}

const TEMP_THRESHOLD_METRIC = 28;

export default function CanopyStress(props: ICanopyStressProps) {

  const debug = useAppSelector((state) => state.user.debug);
  const { t } = useTranslation("CanopyStress");
  const { interval, setInterval, dateRange, locationID, canopyStressForecast, soilProbesData, refetchCanopyStress, locationDevicesData } = useFieldDetailContext();

  const numericSystem: string = useSelector((state: RootState) => state.user.numericSystem);
  const tempratureUnit = getUnitName(numericSystem, "\u00B0C");
  const { loading, legend, CanopyStressGraphSeries } = useCanopyStressContext();
  // const [isForecastUnavailable, setIsForeacastUnavailable] = useState(false);
  // useEffect(() => {
  //   if (debug) {
  //     return setIsForeacastUnavailable(false);
  //   }
  //   else setIsForeacastUnavailable(CanopyStressGraphSeries?.isForecastUnavailable);
  // }, [CanopyStressGraphSeries?.isForecastUnavailable, debug])

  const columnNamesStress: string[] = t("GraphSeriesStress", { returnObjects: true });
  const columnNamesTemp: string[] = t("GraphSeriesTemp", { returnObjects: true });
  const columnNamesHumidity: string[] = t("GraphSeriesHumidity", { returnObjects: true });

  const isDripDaily = React.useMemo(() => soilProbesData.data?.data?.value?.length ? soilProbesData.data?.data?.value[0].IrrigationMethodID == 2 : false, [soilProbesData.data]);

  const thresholdDateRangeGenerator = (num: number | null) => {
    const data = CanopyStressGraphSeries?.acc_stress_time_under__one_hundred?.data;
    if (
      Array.isArray(data) &&
      data.length > 0 &&
      data[0] &&
      data[data.length - 1] &&
      data[0][0] &&
      data[data.length - 1][0]
    ) {
      return [
        [data[0][0], num],
        [data[data.length - 1][0], num],
      ];
    }

    return [[null, null]];
  };

  React.useEffect(() => {
    const threshold = thresholdDateRangeGenerator(soilProbesData.data?.data?.value?.length ? soilProbesData.data.data.value[0].TempThreshold : TEMP_THRESHOLD_METRIC)
    console.log('threshold', threshold, numericValueFormatter(numericSystem, soilProbesData.data?.data?.value?.length ? soilProbesData.data.data.value[0].TempThreshold : TEMP_THRESHOLD_METRIC, "\u00B0C"))
  }, [soilProbesData])

  const roles = useSelector((state: RootState) => state.user.role);
  const isAdmin = roles.find((role: string) => role == "Goanna Administrators")
  const formatter = useTooltipFormatterWithDateFormat({
    [columnNamesStress[0]]: "%",
    [columnNamesStress[1]]: "%",
    [columnNamesStress[2]]: "%",
    [columnNamesStress[4]]: numericSystem === 'M' ? "W/m\u00B2" : "Lang/Hr",
    [columnNamesStress[5]]: "%",
    [columnNamesStress[3]]: numericSystem === 'M' ? "W/m\u00B2" : "Lang/Hr",
    "Canopy Temp": getUnitName(numericSystem, "°C"),
    "Canopy Temp Forecast": getUnitName(numericSystem, "°C"),
    [columnNamesTemp[0]]: getUnitName(numericSystem, "°C"),
    [columnNamesTemp[1]]: getUnitName(numericSystem, "°C"),
    [columnNamesTemp[2]]: getUnitName(numericSystem, "°C"),
    [columnNamesHumidity[0]]: "%",
    [columnNamesHumidity[1]]: "%",
  }, undefined, false);

  const [legendSelectionModel, setLegendSelectionModel] = React.useState<{ [x: string]: boolean; }>({
    'Air Temperature': false,
    'Ambient Temperature': false,
    'Humidity': false,
    'Air Temperature Forecast': false,
    [columnNamesStress[3]]: false,
    'Humidity Forecast': false,
    [columnNamesStress[4]]: false,
  });

  const [legendSelectionModel2, setLegendSelectionModel2] = React.useState<{ [x: string]: boolean; }>({
    'Air Temperature': false,
    'Ambient Temperature': false,
    'Humidity': false,
    'Air Temperature Forecast': false,
    [columnNamesStress[3]]: false,
    'Humidity Forecast': false,
  });


  function updateLegends(selected: { [x: string]: boolean; }) {
    const temp: { [x: string]: boolean; } = {}
    const temp2: { [x: string]: boolean; } = {}

    Object.keys(selected).forEach(a => {
      if (columnNamesStress.includes(a)) temp[a] = selected[a];
      else if (legend.includes(a)) temp2[a] = selected[a]
    })

    setLegendSelectionModel(temp);
    setLegendSelectionModel2(temp2);
  }

  const temp1: EChartsOption["series"] = [];

  const graphSeries: EChartsOption["series"] = [
    {
      name: columnNamesStress[5],
      xAxisIndex: 0,
      yAxisIndex: 1,
      type: 'line',
      data: thresholdDateRangeGenerator(100),
      tooltip: {
        show: false,
      },
      symbol: 'none',
      lineStyle: {
        width: 1,
      },
      itemStyle: {
        color: "#FF0000"
      },
      markLine: {
        data: [
          { // 0
            name: columnNamesStress[5],
            yAxis: 100,
            lineStyle: {
              color: '#FF0000',
              width: 3,
              type: 'solid'
            },
            label: {
              position: 'insideEndTop',
              formatter: '{c}%'
            }
          },
        ],
        symbol: ['none', 'none'],
      },
    },
    { // 1
      name: 'Canopy Temp',
      type: 'line',
      xAxisIndex: 1,
      yAxisIndex: 0,
      data: CanopyStressGraphSeries?.temp?.data ?? [],
      smooth: false,
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      itemStyle: {
        color: "#7B7442"
      },
      tooltip: {
        valueFormatter: (value: any) => {
          return valueFormatter(value, tempratureUnit)
        }
      },
      sampling: 'lttb',
    },
    { // 2
      name: 'Air Temperature',
      type: 'line',
      xAxisIndex: 1,
      yAxisIndex: 0,
      data: CanopyStressGraphSeries?.air_temp?.data ?? [],
      smooth: false,
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      itemStyle: {
        color: "#faa334"
      },
      tooltip: {
        valueFormatter: (value: any) => {
          return valueFormatter(value, tempratureUnit)
        }
      },
      sampling: 'lttb',
    },
    isAdmin ? { // 3
      name: 'Ambient Temperature',
      type: 'line',
      xAxisIndex: 1,
      yAxisIndex: 0,
      data: CanopyStressGraphSeries?.ambient_temp?.data ?? [],
      smooth: false,
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      itemStyle: { color: "#0958FF" },
      tooltip: {
        valueFormatter: (value: any) => {
          return valueFormatter(value, numericSystem === 'M' ? '\u00B0C' : '\u00B0F')
        }
      },
      sampling: 'lttb',
    } :
      {
        name: '',
        type: 'line',
        xAxisIndex: 1,
        yAxisIndex: 0,
        data: [],
      },
    { // 4
      name: 'Humidity',
      type: 'line',
      xAxisIndex: 1,
      yAxisIndex: 2,
      data: CanopyStressGraphSeries?.rel_humid?.data ?? [],
      smooth: false,
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      itemStyle: { color: "#5232a8" },
      sampling: 'lttb',
    },
    !isDripDaily ? { // 5
      name: columnNamesStress[0],
      type: 'line',
      areaStyle: {},
      data: CanopyStressGraphSeries?.acc_stress_time_under__one_hundred?.data ?? [],
      xAxisIndex: 0,
      yAxisIndex: 1,
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      itemStyle: {
        color: "#2D9C41",
        borderWidth: 2,
        borderColor: '#2D9C41'
      },
      tooltip: {
        valueFormatter: (value: any) => {
          return valueFormatter(value, '%')
        }
      },
      sampling: 'lttb',
    } :
      { // 5
        name: columnNamesStress[1],
        data: (CanopyStressGraphSeries?.stress_time?.data ?? []).map(value => [value[0], Math.min(value[1], 100)]),
        type: 'bar',
        xAxisIndex: 0,
        yAxisIndex: 1,
        stack: 'total',
        itemStyle: {
          color: '#2D9C41'
        },
        sampling: "none"
      },
    !isDripDaily ? { // 6
      name: columnNamesStress[1],
      type: 'line',
      areaStyle: {},
      data: CanopyStressGraphSeries?.acc_stress_time_over__one_hundred?.data ?? [],
      xAxisIndex: 0,
      yAxisIndex: 1,
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      itemStyle: {
        color: "#FFCDD2",
        borderWidth: 2,
        borderColor: '#FFCDD2'
      },
      tooltip: {
        valueFormatter: (value: any) => {
          return valueFormatter(value, '%')
        }
      },
    } :
      { // 6
        name: columnNamesStress[1],
        data: (CanopyStressGraphSeries?.stress_time?.data ?? []).map(value => [value[0], Math.max(0, value[1] - 100)]),
        type: 'bar',
        xAxisIndex: 0,
        yAxisIndex: 1,
        stack: 'total',
        itemStyle: {
          color: '#FFCDD2'
        },
      },
    //   isForecastUnavailable ?
    // {
    //   name: '',
    //   type: 'line',
    //   xAxisIndex: 1,
    //   yAxisIndex: 0,
    //   data: [],
    // }
    // :
    { // 7
      name: columnNamesStress[2],
      type: 'line',
      data: CanopyStressGraphSeries?.stress_time_forecast?.data ?? [],
      xAxisIndex: 0,
      yAxisIndex: 1,
      color: 'green',
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      itemStyle: {
        color: (params: any) => {
          if (params.value > 100) {
            return 'green';
          }
          return 'red';
        },
        // borderWidth: 2,
        // borderColor: '#FFFFFF'
      },
      sampling: 'lttb',
      lineStyle: {
        type: 'dashed'
      },
      markPoint: {
        data: CanopyStressGraphSeries?.stressValue ? [
          {
            name: 'Canopy Stress Forecast markPoint',
            coord: CanopyStressGraphSeries?.stressValue,
            value: CanopyStressGraphSeries?.stressValue[0] ? dateFormatter(numericSystem, CanopyStressGraphSeries?.stressValue[0]) as any : null,
          },
        ]
          :
          [],
        label: {
          formatter: '{c}',
          color: 'black',
          offset: [0, -29],
          borderWidth: 1,
          borderColor: 'black',
          padding: 5
        },
        symbol: `image://${url.default}`,
        symbolSize: 40,
        symbolOffset: [1, -20],
        symbolRotate: 0,
        emphasis: {
          disabled: true
        }
      },
    },
    { // 8
      name: 'Air Temperature Forecast',
      type: "line",
      xAxisIndex: 1,
      yAxisIndex: 0,
      data: CanopyStressGraphSeries?.air_temp_forecast?.data ?? [],
      smooth: false,
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      // itemStyle: {
      //   borderWidth: 2,
      //   borderColor: '#FFFFFF'
      // },
      sampling: 'lttb',
      lineStyle: {
        type: 'dashed'
      },
      itemStyle: { color: "#FB8C09" },
      tooltip: {
        valueFormatter: (value: any) => {
          return valueFormatter(value, tempratureUnit)
        }
      }
    },
    { // 9
      name: columnNamesStress[4],
      type: 'line',
      data: CanopyStressGraphSeries?.solar_rad_forecast?.data ?? [],
      smooth: false,
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      // itemStyle: {
      //   borderWidth: 2,
      //   borderColor: '#FFFFFF'
      // },
      sampling: 'lttb',
      xAxisIndex: 0,
      yAxisIndex: 3,
      lineStyle: {
        type: 'dashed'
      },
      itemStyle: { color: "#5B22DF" },
      tooltip: {
        valueFormatter: (value: any) => {
          return valueFormatter(value, numericSystem === 'M' ? 'W/m\u00B2' : 'Lang/Hr')
        }
      }
    },
    {// 10
      name: columnNamesStress[3],
      type: 'line',
      data: CanopyStressGraphSeries?.solar_rad?.data ?? [],
      smooth: false,
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      sampling: 'lttb',
      xAxisIndex: 0,
      yAxisIndex: 3,
      itemStyle: { color: "#A514BF" },
      tooltip: {
        valueFormatter: (value: any) => {
          return valueFormatter(value, numericSystem === 'M' ? 'W/m\u00B2' : 'Lang/Hr')
        }
      }
    },
    { // 10
      name: 'Humidity Forecast',
      type: 'line',
      xAxisIndex: 1,
      data: CanopyStressGraphSeries?.rel_humid_forecast?.data ?? [],
      smooth: false,
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      itemStyle: { color: "#0B5234" },
      sampling: 'lttb',
      lineStyle: {
        type: 'dashed'
      },
      yAxisIndex: 2,
    },
    // isForecastUnavailable ?
    // {
    //   name: '',
    //   type: 'line',
    //   xAxisIndex: 1,
    //   yAxisIndex: 0,
    //   data: [],
    // } :
    { // 11
      name: 'Canopy Temp Forecast',
      type: 'line',
      xAxisIndex: 1,
      data: CanopyStressGraphSeries?.canopy_temp_forecast?.data ?? [],
      smooth: false,
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      itemStyle: { color: "#FF0000" },
      sampling: 'lttb',
      yAxisIndex: 0,
      lineStyle: {
        type: 'dashed'
      },
      tooltip: {
        valueFormatter: (value: any) => {
          return valueFormatter(value, tempratureUnit)
        }
      }
    },
    { // 12
      name: 'Temp Threshold',
      xAxisIndex: 1,
      yAxisIndex: 0,
      type: 'line',
      data: thresholdDateRangeGenerator(soilProbesData.data?.data?.value[0].TempThreshold? soilProbesData.data.data.value[0].TempThreshold : TEMP_THRESHOLD_METRIC),
      symbol: 'none',
      tooltip: {
        show: false,
      },
      lineStyle: {
        width: 1,
        color: '#FFFFFF00',
      },
      itemStyle: { color: "#FF0000" },
      markLine: {
        data: [
          {
            name: columnNamesStress[5],
            yAxis: soilProbesData.data?.data.value[0].TempThreshold ? soilProbesData.data.data.value[0].TempThreshold : TEMP_THRESHOLD_METRIC,
            lineStyle: {
              color: '#FF0000',
              width: 3,
              type: 'solid'
            },
            label: {
              position: 'insideEndTop',
              // formatter: `{c} ${getUnitName(numericSystem, "\u00B0C")}`
            }
          },
        ],
        symbol: ['none', 'none'],
      },
      sampling: 'lttb',
    },
  ]

  if (isDripDaily) {
    graphSeries.splice(7, 1, { // 7
      name: columnNamesStress[2],
      data: (CanopyStressGraphSeries?.stress_time_forecast_bar?.data ?? []).map(value => {
        const a = Math.min(value[1], 100)
        return [value[0], a ? a : null];
      }),
      type: 'bar',
      xAxisIndex: 0,
      yAxisIndex: 1,
      stack: 'forecast_total',
      itemStyle: {
        borderWidth: 1,
        color: 'rgba(0, 0, 0, 0)',
        borderType: 'dashed',
        borderColor: '#2D9C41',
        borderDashOffset: 2,
      },
    }, { // 8
      name: columnNamesStress[2],
      data: (CanopyStressGraphSeries?.stress_time_forecast_bar?.data ?? []).map(value => {
        const a = Math.max(0, value[1] - 100)
        return [value[0], a ? a : null];
      }),
      type: 'bar',
      xAxisIndex: 0,
      yAxisIndex: 1,
      stack: 'forecast_total',
      itemStyle: {
        borderWidth: 1,
        color: 'rgba(0, 0, 0, 0)',
        borderType: 'dashed',
        borderColor: '#FF0000',
        borderDashOffset: 2,
      }
    });
  }


  const sumDataForSeries = (param: any[], idx: number, seriesName: string) => {
    if (idx === -1) return null;

    const seriesData = param[idx].data[0];
    const aggregatedData = param
      .filter((a: any) => a.seriesName === seriesName)
      .map((a: { data: number[] }) => a.data[1])
      .reduce((partialSum: number, a: number) => partialSum + a, 0) || null;

    return [seriesData, aggregatedData];
  };

  const option: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      textStyle: {
        fontWeight: 'normal',
      },
      axisPointer: {
        type: 'cross'
      },
      formatter: isDripDaily ? (param: any): any => {
        let i = param.findIndex((a: any) => a.seriesName === "Deficit Stress");
        let j = param.findIndex((a: any) => a.seriesName === "Deficit Stress forecast");

        if (i === -1 && j === -1) return formatter(param);

        const deficitData = sumDataForSeries(param, i, "Deficit Stress");
        const deficitForecastData = sumDataForSeries(param, j, "Deficit Stress forecast");

        const newParam = param;

        if (deficitData) {
          i = param.findIndex((a: any) => a.seriesName === "Deficit Stress");
          newParam.splice(i, 2, {
            seriesName: "Deficit Stress",
            data: deficitData,
            color: deficitData[1] < 100 ? param[i].color : "#FFCDD2",
            axisValueLabel: param[i].axisValueLabel
          })
        }
        if (deficitForecastData) {
          j = newParam.findIndex((a: any) => a.seriesName === "Deficit Stress forecast");

          newParam.splice(j, 2, {
            seriesName: "Deficit Stress forecast",
            data: deficitForecastData,
            color: "#F00",
            axisValueLabel: param[j].axisValueLabel
          })
        }
        return formatter(newParam);
      }
        : formatter
    },
    legend: [
      {
        data: columnNamesStress,
        type: 'scroll',
        width: "50%",
        selected: legendSelectionModel
      },
      {
        data: legend,
        type: 'scroll',
        width: "50%",
        bottom: 55,
        left: 'center',
        selected: legendSelectionModel2
      }
    ],
    axisPointer: {
      link: [
        {
          xAxisIndex: 'all'
        }
      ]
    },
    dataZoom: {
      type: 'slider',
      xAxisIndex: [0, 1],
    },
    grid: [
      {
        left: 90,
        right: 100,
        height: '40%'
      },
      {
        left: 90,
        right: 100,
        top: '49%',
        height: '40%',
      }
    ],
    xAxis: [
      {
        type: 'time',
        boundaryGap: false,
        min: dateRange[0]?.toISODate(),
        max: dateRange[1]?.toISODate(),
        axisPointer: {
          label: {
            show: false,
            formatter: function (param: any) {
              const dateTimeFormat = numericSystem === "M" ? "DD/MM/YYYY HH:mm:ss" : "MM/DD/YYYY HH:mm:ss"
              return moment(param.value).format(dateTimeFormat);
            }
          }
        },
      },
      {
        gridIndex: 1,
        show: false,
        type: 'time',
        boundaryGap: false,
        position: 'top',
        min: dateRange[0]?.toISODate(),
        max: dateRange[1]?.toISODate(),
        axisPointer: {
          label: {
            formatter: function (param: any) {
              const dateTimeFormat = numericSystem === "M" ? "DD/MM/YYYY HH:mm:ss" : "MM/DD/YYYY HH:mm:ss"
              return moment(param.value).format(dateTimeFormat);
            }
          }
        },
      }
    ] as XAXisOption,
    yAxis: [
      { //0
        name: `Temperature(${tempratureUnit})`,
        type: 'value',
        gridIndex: 1,
        // axisLabel: {
        //   formatter: `{value} ${getUnitName(numericSystem, "\u00B0C")}`
        // }
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          fontSize: '1rem',
          color: '#000000',
        },
      },
      { //1
        name: t("title"),
        type: 'value',
        scale: true,
        min: 0,
        axisLabel: {
          formatter: '{value} %'
        },
        nameLocation: 'middle',
        nameGap: 50,
        nameTextStyle: {
          fontSize: '1rem',
          color: '#000000',
        },
      },
      { //2
        name: 'Humidity',
        type: 'value',
        axisLabel: {
          formatter: '{value} %'
        },
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          fontSize: '1rem',
          color: '#000000',
        },
        gridIndex: 1,
        splitLine: {
          show: false,
        },
      },
      { //3
        name: 'Solar Radiation',
        type: 'value',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          fontSize: '1rem',
          color: '#000000',
        },
        splitLine: {
          show: false,
        },
      },
    ],
    visualMap: isDripDaily ? undefined : [
      {
        show: false,
        dimension: 1,
        seriesIndex: 7,
        pieces: [{
          gt: 0,
          lte: 100,
          color: '#2D9C41'
        }],
        outOfRange: {
          color: 'red'
        }
      }
    ],
    series: graphSeries,
    //   graphic: isForecastUnavailable ? 
    //   [
    //     {
    //         type: 'group',
    //         right: 140,
    //         top: 30,
    //         children: [
    //           {
    //             type: 'rect',
    //             z: 99,
    //             left: 'center',
    //             top: 'middle',
    //             shape: {
    //                 width: 250,
    //                 height: 35,
    //                 r: 8,
    //             },
    //             style: {
    //                 fill: '#fff',
    //                 shadowBlur: 10,
    //                 shadowColor: 'rgba(0, 0, 0, 0.3)',
    //                 shadowOffsetX: 3,
    //                 shadowOffsetY: 3,
    //             },
    //         },
    //             {
    //                 type: 'text',
    //                 z: 100,
    //                 left: 'center',
    //                 top: 'middle',
    //                 style: {
    //                     fill: '#333',
    //                     text: `Forecast temporarily unavailable`,
    //                     font: '14px Arial',
    //                 },
    //             },
    //         ],
    //     },
    // ] : [],
  };

  //add intervals
  React.useEffect(() => {
    refetchCanopyStress()
  }, [interval])

  //header section
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState<DateTime | null>(null);
  const [currentResetDate, setCurrentResetDate] = React.useState<DateTime | null>(null);
  const [resetLoading, setResetLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const locationDateNOW: string = aestToLocationLocalTimeConverter(NOW.toISO(), soilProbesData.data?.data.value[0].GMTDifference);

  React.useEffect(() => {
    locationDateNOW && setDate(DateTime.fromISO(locationDateNOW));
  }, [soilProbesData.data?.data.value[0].GMTDifference])

  const handleClickOpen = () => {
    let requestBody: any = {
      "LocationID": locationID.toString(),
      "ProbeType": String(1)
    };
    http.get(
      'odata/Reset',
      {
        params: requestBody,
      }
    ).then((response) => {
      if (response.data?.value) {
        const resetDate = DateTime.fromJSDate(new Date(response.data?.value));
        if (resetDate.isValid) {
          setCurrentResetDate(resetDate);
        }
        else {
          setCurrentResetDate(null);
        }
      }
    }).catch((e) => {
      console.error('errored', e)
      setDate(null);
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = () => {
    if (date === null || !date.isValid) {
      return
    }
    setResetLoading(true);
    let requestBody: any = {
      "LocationID": locationID.toString(),
      "Date": date.minus({ hour: soilProbesData.data?.data.value[0].GMTDifference }).toFormat("yyyy-MM-dd'T'hh:mm:ss"),
      "ProbeType": String(1)
    };
    http.post(
      'odata/Reset',
      null,
      { params: requestBody }
    ).then(() => {
      refetchCanopyStress();
      canopyStressForecast.refetch();
      setResetLoading(false);
      locationDateNOW && setDate(DateTime.fromISO(locationDateNOW));
      handleClose();
      enqueueSnackbar({
        variant: "success",
        persist: false,
        message: 'Manual reset successful.'
      })
    }).catch((e) => {
      console.error('errored: ', e);
      enqueueSnackbar({
        variant: "error",
        persist: false,
        message: 'Error! Manual reset unsuccessful.'
      })
      setResetLoading(false);
    });
  }

  let heading = "";

  // let isFlood = soilProbesData.data?.data.value[0].IrrigationMethodID === 1;
  if (!isDripDaily) {
    heading = `( Hours of Deficit Stress time above 100%: ${CanopyStressGraphSeries.stressHoursAbove100 ?? '-'} )`;
  } else {
    heading = `( Hours of Deficit Stress: ${CanopyStressGraphSeries.sumStressAbove5hrs ?? '-'} )`;
  }

  const showSubheader = (
    locationDevicesData.data?.data.value[0].StartDateCanopy &&
    soilProbesData.data &&
    soilProbesData.data?.data.value.length > 0 &&
    soilProbesData.data?.data.value[0].GMTDifference !== null &&
    soilProbesData.data?.data.value[0].GMTDifference !== undefined
  ) ? (
    // the start of the current day (AEST time) is earlier than the converted StartDateCanopy time
    DateTime.local({ zone: AEST_Zone }).startOf("day")
    < DateTime.fromISO(
      aestToLocationLocalTimeConverter(
        locationDevicesData.data?.data.value[0].StartDateCanopy,
        soilProbesData.data?.data.value[0].GMTDifference),
      { zone: AEST_Zone }
    )
  ) : false;

  return (
    <FieldDetailPaper width='100%' id='CanopyStressGraph'>
      <FieldDetailHeader backgroundColor='#D9D7D780'>
        <div className={styles.CanopyStressHeader}>
          <div className={styles.headerLeft}>
            {t('title')}
            {!showSubheader && <span className={styles.stressHours}>{heading}</span>}
            <HelpIconButton
              content={[{
                title: t('title'),
                content: "Can have significant effects on crop yield. Deficit Stress occurs when a plant is not receiving enough water to meet its physiological needs. When a plant is experiencing Deficit stress it can result in reduced growth, wilting and reduction in yield. Deficit Stress will affect physiological processes such as photosynthesis, transpiration and nutrient uptake."
              }]}
            />
            {
              showSubheader &&
              <Alert severity='warning' sx={{ marginLeft: "30px" }}>
                {
                  t("subTitle", {
                    interpolation: { escapeValue: false },
                    dateValue: dateFormatter(numericSystem,
                      aestToLocationLocalTimeConverter(
                        locationDevicesData.data?.data.value[0].StartDateCanopy,
                        soilProbesData.data?.data.value[0].GMTDifference))
                  })
                }
              </Alert>
            }
          </div>
          <div className={styles.headerRight}>
            {
              (isAdmin && !showSubheader) &&
              <GoannaButton
                title='Manual Reset'
                onClick={handleClickOpen}
                width='163px'
                height='42px'
              />
            }
            <ResetDialog date={date} setDate={setDate} currentResetDate={currentResetDate} open={open} resetLoading={resetLoading} handleClose={handleClose} handleReset={handleReset} locationDateNow={locationDateNOW} />
          </div>
        </div>
      </FieldDetailHeader>
      {
        !showSubheader && <FieldDetailBody className={styles.fieldDetailBody} style={{ marginLeft: '0px', marginRight: '0px' }} loading={loading}>
          {
            debug ?
              <div>
                <select value={interval} onChange={(event) => setInterval(event.target.value)}>
                  <option value="0">off</option>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="60">60</option>
                </select>
              </div>
              :
              <></>
          }
          <ReactECharts onLegendChange={({ selected }: any) => { updateLegends(selected); }} option={option} style={{ height: '1000px', width: '100%' }} loading={loading} />
        </FieldDetailBody>
      }
    </FieldDetailPaper>
  );
}
