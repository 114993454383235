import { useSelector } from 'react-redux';
import { EChartsOption } from 'echarts';
import * as React from 'react';
import FieldDetailBody from '../../../../../components/FieldDetailPaper/FieldDetailBody';
import FieldDetailHeader from '../../../../../components/FieldDetailPaper/FieldDetailHeader';
import FieldDetailPaper from '../../../../../components/FieldDetailPaper/FieldDetailPaper';
import { ReactECharts } from '../../../../../components/ReactEcharts/ReactEcharts';
import useFieldDetailContext from '../../../../../hooks/ContextHooks/useFieldDetailContext';
import useSoilDetailContext from '../../../../../hooks/ContextHooks/useSoilTotalContext';

import styles from './style.module.css';
import { valueFormatter } from '../../../../../utils/Echarts';
import { http } from '../../../../../api/http';
import { Checkbox, TextField, Tooltip } from '@mui/material';
import { dateFormatter, getUnitName, valueRounder } from '../../../../../utils/numericConversions';
import GoannaButton from '../../../../../components/GoannaButton/GoannaButton';
import { useTranslation } from 'react-i18next';
import * as url4 from '../../../../../assets/images/MarkPoint/Line.png';
import * as url3 from '../../../../../assets/images/MarkPoint/Balloon3.png';
import moment from 'moment';
import { RootState } from '../../../../../stores/store';
import { XAXisOption } from 'echarts/types/dist/shared';
import { useSnackbar } from 'notistack';
import HelpIconButton from '../HelpIconButton/HelpIconButton';
import { useAppSelector } from '../../../../../stores/hooks';

export interface ISoilTotalProps { }

export default function SoilTotal(props: ISoilTotalProps) {

  const { t } = useTranslation("soilTotal");

  const { dateRange, userAccounts, locationDevicesData: _locationDevicesData, locationID, soilProbesData } = useFieldDetailContext();
  const roles = useAppSelector((state) => state.user).role;
  const isAdmin = roles.includes("Goanna Administrators");
  const { legend, soilTotalGraphSeries, loading } = useSoilDetailContext();
  const { dailyUsageMap } = soilTotalGraphSeries;
  const { numericSystem }: { numericSystem: string } = useSelector((state: RootState) => state.user);
  const lengthUnit = numericSystem === "M" ? "mm" : "In";
  const lastEtcDataIndex = soilTotalGraphSeries?.IrrigationForecastEtc?.data.length - 1;
  const lastEtcDataValue = soilTotalGraphSeries?.IrrigationForecastEtc?.data[lastEtcDataIndex] ?? null
  const lastDefaultDataIndex = soilTotalGraphSeries?.IrrigationForecastDefault?.data.length - 1;
  const lastDefaultDataValue = soilTotalGraphSeries?.IrrigationForecastDefault?.data[lastDefaultDataIndex] ?? null
  const lastCurrentUsageDataIndex = soilTotalGraphSeries?.IrrigationForecastActial?.data.length - 1;
  const lastCurrentUsageDataValue = soilTotalGraphSeries?.IrrigationForecastActial?.data[lastCurrentUsageDataIndex] ?? null;
  const lastSoilTotalIndex = soilTotalGraphSeries?.SoilToal?.data.length - 1;
  const lastSoilTotalValue = soilTotalGraphSeries?.SoilToal?.data[lastSoilTotalIndex] ?? null;
  const positionThreshold = numericSystem === "M" ? 140 : 5.51181102;
  const [firstLoad, setFirstLoad] = React.useState(0);

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    setFirstLoad((prevState) => prevState + 1)
  }, []);

  const [legendSelectionModel, setLegendSelectionModel] = React.useState({
    [t('soilTotal-10DaysRainForecast')]: true,
    [t('soilTotal-CurrentUseDefault')]: false,
    [t('soilTotal-CurrentUsageIrrigate')]: false,
    [t('soilTotal-DailyUsage')]: false,
    [t('soilTotal-DailyUsageinch/10')]: false,
    [t('soilTotal-IrrigationForecastDefault')]: false,
  });

  const color = [
    '#0B5234',
    '#80d463',
    '#124BC5',
    '#FB8C09',
    '#5B22DF',
    '#FFEA00',
    '#E22C29',
    '#FB8C09',
    '#5B22DF',
    '#FFEA00',
    '#FFFFFF',
  ];

  const option: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      textStyle: {
        fontWeight: 'normal'
      },
      axisPointer: {
        type: 'cross',
      },
      formatter: (param: any) => {
        let res = `<div style="margin-bottom:2px;">${param[0].axisValueLabel}</div>`;
        for (let i = 0; i < param.length; i++) {
          if (param[i].data && param[i].data.length === 2) {
            if (param[i].seriesName === 'Daily Usage' || (param[i].seriesName === 'Daily Usage inch/10')) {
              const dateTime = param[i].data[0];
              const data = dailyUsageMap.get(dateTime);
              res +=
                `<div>
                  <span style="display:flex;flex-direction:row;justify-content:space-between">
                    <span>
                      <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param[i].color};"></span>
                      ${param[i].seriesName}:&nbsp &nbsp &nbsp &nbsp
                    </span>
                    <span>${(data ? valueFormatter(data, numericSystem === "M" ? "mm" : "In/10") : '-')}&nbsp </span>
                  </span>
                </div>`;
              continue;
            }
            if (param[i].seriesName === t("soilTotal-IrrigationForecastDefault")) {
              res +=
                `<div>
                  <span style="display:flex;flex-direction:row;justify-content:space-between">
                    <span>
                      <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param[i].color};"></span>
                      ${t("soilTotal-IrrigationForecastDefault")}:&nbsp &nbsp &nbsp &nbsp
                    </span>
                    <span>${lastDefaultDataValue[0] ? dateFormatter(numericSystem, lastDefaultDataValue[0]) as any : "-"}&nbsp </span>
                  </span>
                </div>`;
              continue;
            }
            if (param[i].seriesName === t("soilTotal-IrrigationForecast")) {
              res +=
                `<div>
                  <span style="display:flex;flex-direction:row;justify-content:space-between">
                    <span>
                      <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param[i].color};"></span>
                      ${t("soilTotal-IrrigationForecast")}:&nbsp &nbsp &nbsp &nbsp
                    </span>
                    <span>${lastEtcDataValue[0] ? dateFormatter(numericSystem, lastEtcDataValue[0]) as any : "-"}&nbsp </span>
                  </span>
                </div>`;
              continue;
            }
            if (param[i].seriesName === t("soilTotal-CurrentUsageIrrigate")) {
              res +=
                `<div>
                  <span style="display:flex;flex-direction:row;justify-content:space-between">
                    <span>
                      <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param[i].color};"></span>
                      ${t("soilTotal-CurrentUsageIrrigate")}:&nbsp &nbsp &nbsp &nbsp
                    </span>
                    <span>${lastCurrentUsageDataValue ? dateFormatter(numericSystem, lastCurrentUsageDataValue[0]) as any : "-"}&nbsp </span>
                  </span>
                </div>`;
              continue;
            }
            res +=
              `<div>
                <span style="display:flex;flex-direction:row;justify-content:space-between">
                  <span>
                    <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param[i].color};"></span>
                    ${param[i].seriesName}:&nbsp &nbsp &nbsp &nbsp
                  </span>
                  <span>${(param[i].data[1] !== null ? valueFormatter(param[i].data[1], lengthUnit) : '-')}&nbsp </span>
                </span>
              </div>`;
            if (param[i].seriesName === t("soilTotal-soilTotal") && (soilTotalGraphSeries?.SoilToal?.data && (soilTotalGraphSeries?.SoilToal?.data ?? []).length - 1 === param[i].dataIndex)) {
              res +=
                `<div>
                  <span style="display:flex;flex-direction:row;justify-content:space-between">
                    <span>
                      <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param[i].color};"></span>
                      current daily use:&nbsp &nbsp &nbsp &nbsp
                    </span>
                    <span>${valueFormatter(soilTotalGraphSeries?.DefaultUsage?.data, numericSystem === "M" ? 'mm' : 'In/10') ?? t('soilTotal-NoData')}&nbsp </span>
                  </span>
                </div>`;
            }
          }
        }
        return res;
      }
    },
    legend: {
      data: legend,
      selected: legendSelectionModel
    },
    dataZoom: {
      type: 'slider'
    },
    color: color,
    grid: {
      left: 90,
      right: 90,
    },
    xAxis: [{
      type: 'time',
      // type为time时,不要传xAxis.data的值,x轴坐标的数据会根据传入的时间自动展示
      // Translated: =>
      // When the type is time, do not pass the value of xAxis.data, 
      // the data of the x-axis coordinates will be automatically displayed according to the time passed in
      min: dateRange[0]?.toISODate(),
      max: dateRange[1]?.toISODate(),
      axisPointer: {
        label: {
          formatter: function (param: any) {
            const dateTimeFormat = numericSystem === "M" ? "DD/MM/YYYY HH:mm:ss" : "MM/DD/YYYY HH:mm:ss"
            return moment(param.value).format(dateTimeFormat);
          }
        }
      },
    } as XAXisOption],
    yAxis: [
      {
        position: 'right',
        type: 'value',
        name: t("soilTotal-RainfallWithCount", { lengthUnit }), //`Rainfall(${length})`,
        scale: true,
        min: 0,
        max: soilTotalGraphSeries.MaxRain !== 0 ? soilTotalGraphSeries.MaxRain * 2 : undefined,
        axisLabel: {
          // formatter: `{value} ${getUnitName(numericSystem, "mm")}`
        },
        splitLine: {
          show: false,
        },
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          fontSize: '1rem',
          color: '#000000'
        },
      },
      {
        position: 'left',
        type: 'value',
        name: t("soilTotal-SoilTotalWithUnit", { lengthUnit }),
        axisTick: {
          show: false,
        },
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          fontSize: '1rem',
          color: '#000000',
        },
        scale: true,
        min: (value) => {
          if (value.min < soilTotalGraphSeries?.RefillPoint) {
            return Number((value.min * 0.95).toFixed(1));
          }
          else {
            return Number((soilTotalGraphSeries?.RefillPoint * 0.90).toFixed(1));
          }
        },
        max: (value) => {
          if (value.max > soilTotalGraphSeries?.FullPoint) {
            return Number((value.max * 1.05).toFixed(1));
          }
          else {
            return Number((soilTotalGraphSeries?.FullPoint * 1.05).toFixed(1));
          }

        },
        axisLabel: {
          // formatter: `{value} ${getUnitName(numericSystem, "mm")}`
        }
      }
    ],
    series: [
      {
        name: t('soilTotal-Rainfall'),
        type: 'bar',
        yAxisIndex: 0,
        barWidth: 4,
        data: soilTotalGraphSeries?.RainEvent?.data ?? [],
        label: {
          show: true,
          position: 'top'
        },
        tooltip: {
          valueFormatter: (value: any) => {
            return valueFormatter(value, lengthUnit)
          }
        }
      },
      {
        name: t("soilTotal-soilTotal"),
        type: 'line',
        yAxisIndex: 1,
        // symbol: 'none',
        // showSymbol: false,
        showSymbol: true,
        symbolSize: 0.1,
        symbol: 'circle',
        emphasis: {
          scale: 100,
        },
        // itemStyle: {
        //   borderWidth: 2,
        //   borderColor: '#FFFFFF'
        // },
        legendHoverLink: true,
        data: soilTotalGraphSeries?.SoilToal?.data ?? [],
        smooth: false,
        tooltip: {
          valueFormatter: (value: any) => {
            return valueFormatter(value, lengthUnit)
          }
        },
        markPoint: {
          data: [
            {
              name: t('soilTotal-DefaultUsage'),
              value: soilTotalGraphSeries?.DefaultUsage?.data ?? t('soilTotal-NoData'),
              xAxis: soilTotalGraphSeries?.DefaultUsage?.xAxis ?? undefined,
              yAxis: soilTotalGraphSeries?.DefaultUsage?.yAxis ?? undefined
            },
          ],
          label: {
            formatter: (param: any) => {
              return param.data ? valueFormatter(valueRounder(param.data.value, 2), '') : '';
            },
            color: 'black',
            offset: [5, 0]
          },
          symbol: `image://${url3.default}`,
          symbolSize: 40,
          symbolOffset: [17, 0],
          symbolRotate: 270,
          emphasis: {
            disabled: true
          }
        },
        label: {
          show: true,
          position: 'bottom',
          formatter: (param: any) => {
            if (soilTotalGraphSeries?.SoilToal?.data && (soilTotalGraphSeries?.SoilToal?.data ?? []).length - 1 === param.dataIndex) {
              return valueRounder(param.value[1], 2) + ' ';
            }
            else return '';
          }
        }
      },
      {
        name: t("soilTotal-10DaysRainForecast"),
        type: 'bar',
        yAxisIndex: 0,
        data: soilTotalGraphSeries?.RainEventForecast?.data ?? [],
        barWidth: 4,
        tooltip: {
          valueFormatter: (value: any) => {
            return valueFormatter(value, lengthUnit)
          }
        },
        label: {
          show: true,
          position: 'top'
        }
      },
      {
        name: t("soilTotal-CurrentUsageIrrigate"),
        type: 'line',
        yAxisIndex: 1,
        showAllSymbol: true,
        symbolSize: 6,
        symbol: 'emptyCircle',
        data: soilTotalGraphSeries?.IrrigationForecastActial?.data ?? [],
        smooth: false,
        lineStyle: {
          type: 'dashed'
        },
        tooltip: {
          valueFormatter: (value: any) => {
            return valueFormatter(value, lengthUnit)
          }
        }
      },
      {
        name: t("soilTotal-IrrigationForecastDefault"),
        type: 'line',
        yAxisIndex: 1,
        showAllSymbol: true,
        symbolSize: 6,
        symbol: 'emptyCircle',
        data: soilTotalGraphSeries?.IrrigationForecastDefault?.data ?? [],
        smooth: false,
        lineStyle: {
          type: 'dashed'
        },
        tooltip: {
          // show: false,
          valueFormatter: (value: any) => {
            // return lastDefaultDataValue[0] ? dateFormatter(numericSystem, lastDefaultDataValue[0]) as any : null;
            return 'ttttt'
          },
          formatter: 'lastDefaultDataValue',

        }
      },
      {
        name: t("soilTotal-IrrigationForecast"),
        type: 'line',
        yAxisIndex: 1,
        showAllSymbol: true,
        data: soilTotalGraphSeries?.IrrigationForecastEtc?.data ?? [],
        tooltip: {
          valueFormatter: (value: any) => valueFormatter(value, lengthUnit),
        }
      },
      {
        name: t("soilTotal-CurrentUseDefault"),
        type: "effectScatter",
        yAxisIndex: 1,
        symbolSize: 15,
        data: [soilTotalGraphSeries?.SoilToal?.data[soilTotalGraphSeries?.SoilToal?.data.length - 1] ?? []],
        tooltip: {
          valueFormatter: (value: any) => {
            return valueFormatter(value, lengthUnit)
          }
        }
      },
      {
        name: t("soilTotal-CurrentUsageIrrigate"),
        type: "line",
        yAxisIndex: 1,
        symbolSize: 6,
        showAllSymbol: true,
        data: [lastCurrentUsageDataValue ?? [],],
        markPoint: {
          data: lastCurrentUsageDataValue ? [
            {
              name: 'Current usage irrigate',
              coord: lastCurrentUsageDataValue,
              value: lastCurrentUsageDataValue ? dateFormatter(numericSystem, lastCurrentUsageDataValue[0]) as any : null,
            },
          ]
            :
            [],
          label: {
            formatter: '{c}',
            color: 'black',
            offset: (lastSoilTotalValue && lastSoilTotalValue[1] < soilTotalGraphSeries?.RefillPoint) || soilTotalGraphSeries?.RefillPoint < positionThreshold ? [0, -29] : [0, 29],
            borderWidth: 1,
            borderColor: 'black',
            padding: 5
          },
          symbol: `image://${url4.default}`,
          symbolSize: 40,
          symbolOffset: (lastSoilTotalValue && lastSoilTotalValue[1] < soilTotalGraphSeries?.RefillPoint) || soilTotalGraphSeries?.RefillPoint < positionThreshold ? [0, -20] : [0, 20],
          symbolRotate: (lastSoilTotalValue && lastSoilTotalValue[1] < soilTotalGraphSeries?.RefillPoint) || soilTotalGraphSeries?.RefillPoint < positionThreshold ? 0 : 180,
          emphasis: {
            disabled: true
          }
        },
        tooltip: {
          show: false
        },
      },
      {
        name: t("soilTotal-IrrigationForecast"),
        type: "line",
        yAxisIndex: 1,
        data: [lastEtcDataValue ?? [],],
        markPoint: {
          data: lastEtcDataValue ? [
            {
              name: 'Irrigation Forecast',
              coord: lastEtcDataValue,
              value: lastEtcDataValue[0] ? dateFormatter(numericSystem, lastEtcDataValue[0], true) as any : null,
            },
          ]
            :
            [],
          label: {
            formatter: '{c}',
            color: 'black',
            offset: (lastSoilTotalValue && lastSoilTotalValue[1] < soilTotalGraphSeries?.RefillPoint) || soilTotalGraphSeries?.RefillPoint < positionThreshold ? [0, -29] : [0, 29],
            borderWidth: 1,
            borderColor: 'black',
            padding: 5
          },
          symbol: `image://${url4.default}`,
          symbolSize: 40,
          symbolOffset: (lastSoilTotalValue && lastSoilTotalValue[1] < soilTotalGraphSeries?.RefillPoint) || soilTotalGraphSeries?.RefillPoint < positionThreshold ? [0, -20] : [0, 20],
          symbolRotate: (lastSoilTotalValue && lastSoilTotalValue[1] < soilTotalGraphSeries?.RefillPoint) || soilTotalGraphSeries?.RefillPoint < positionThreshold ? 0 : 180,
          emphasis: {
            disabled: true
          }
        },
        tooltip: {
          show: false
        }
      },
      {
        name: t("soilTotal-IrrigationForecastDefault"),
        type: "line",
        yAxisIndex: 1,
        data: [lastDefaultDataValue ?? [],],
        markPoint: {
          data: lastDefaultDataValue ? [
            {
              name: 'Irrigation Forecast Default',
              coord: lastDefaultDataValue,
              value: lastDefaultDataValue[0] ? dateFormatter(numericSystem, lastDefaultDataValue[0], true) as any : null,
            },
          ]
            :
            [],
          label: {
            formatter: '{c}',
            color: 'black',
            offset: (lastSoilTotalValue && lastSoilTotalValue[1] < soilTotalGraphSeries?.RefillPoint) || soilTotalGraphSeries?.RefillPoint < positionThreshold ? [0, -29] : [0, 29],
            borderWidth: 1,
            borderColor: 'black',
            padding: 5
          },
          symbol: `image://${url4.default}`,
          symbolSize: 40,
          symbolOffset: (lastSoilTotalValue && lastSoilTotalValue[1] < soilTotalGraphSeries?.RefillPoint) || soilTotalGraphSeries?.RefillPoint < positionThreshold ? [0, -20] : [0, 20],
          symbolRotate: (lastSoilTotalValue && lastSoilTotalValue[1] < soilTotalGraphSeries?.RefillPoint) || soilTotalGraphSeries?.RefillPoint < positionThreshold ? 0 : 180,
          emphasis: {
            disabled: true
          }
        },
        tooltip: {
          show: false
        }
      },
      {
        name: t("soilTotal-FullPoint"),
        type: 'line',
        yAxisIndex: 1,
        showSymbol: false,
        tooltip: {
          show: false
        },
        data: soilTotalGraphSeries?.SoilToal?.data ?? [],
        itemStyle: {
          color: '#1BD183'
        },
        lineStyle: {
          color: '#1BD183',
          opacity: 0,
        },
        markLine: {
          data: [
            {
              name: t("soilTotal-FullPoint"),
              yAxis: soilTotalGraphSeries?.FullPoint ?? '',
              // yAxis: 300,
              lineStyle: {
                color: '#1BD183'
              },
              label: {
                position: 'insideEndTop',
                formatter: '{c}'
              }
            },
          ],
          symbol: ['none', 'none'],
        }
      },
      {
        name: t("soilTotal-RefillPoint"),
        type: 'line',
        yAxisIndex: 1,
        showSymbol: false,
        tooltip: {
          show: false
        },
        data: soilTotalGraphSeries?.SoilToal?.data ?? [],
        itemStyle: {
          color: '#FF0000'
        },
        lineStyle: {
          color: '#FF0000',
          opacity: 0,
        },
        markLine: {
          data: [
            {
              name: t("soilTotal-RefillPoint"),
              yAxis: soilTotalGraphSeries?.RefillPoint ?? '',
              lineStyle: {
                color: '#FF0000'
              },
              label: {
                position: 'insideEndTop',
                formatter: '{c}'
              }
            }
          ],
          symbol: ['none', 'none'],
        }
      },
      {
        name: numericSystem === 'M' ? 'Daily Usage' : 'Daily Usage inch/10',
        type: 'line',
        yAxisIndex: 1,
        showSymbol: true,
        symbolSize: 0,
        emphasis: {
          itemStyle: {
            borderWidth: 0.5, // Increase border width on emphasis
            borderColor: '#80d463',
          },
        },
        data: soilTotalGraphSeries?.SoilToal?.data ?? [],
        smooth: true,
        lineStyle: {
          color: '#FFFFFF00'
        },
        itemStyle: {
          color: '#f7cf05'
        },
        label: {
          show: true,
          formatter: (param: any) => {
            if (param.data && param.data.length === 2) {
              const currentDate = param.data[0]
              return dailyUsageMap.get(currentDate) ?? '';
            }
            return ''
          }
        },
      }
    ]
  };

  const locationDevicesData = React.useMemo(
    () => _locationDevicesData.data?.data.value[0] ?? {},
    [_locationDevicesData]
  )

  const [fullPoint, setFullPoint] = React.useState(locationDevicesData.Fullpoint);
  const [refillPoint, setRefillPoint] = React.useState(locationDevicesData.Refillpoint);
  const [automaticFullPointSelected, setAutomaticFullPointSelected] = React.useState(locationDevicesData.AutomatedFullPoint);
  const errorMessages = t("soilTotal-ErrorMessages", { returnObjects: true });
  const successMessage = t("soilTotal-SuccessMessage");
  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);
  const isAdminOrAccountAdmin = React.useMemo(() => isAdmin || userAccounts.data?.user.accounts.length > 0, [isAdmin, userAccounts.data?.user.accounts])

  React.useEffect(() => {
    setFullPoint(locationDevicesData.Fullpoint);
    setRefillPoint(locationDevicesData.Refillpoint);
    setAutomaticFullPointSelected(locationDevicesData.AutomatedFullPoint || false);
    
  }, [locationDevicesData]);

  React.useEffect(() => {
    if (locationDevicesData.Refillpoint === null && soilProbesData.data?.data.value[0].IrrigationMethod === null) {
      enqueueSnackbar(errorMessages[3], { variant: 'warning', persist: false, autoHideDuration: 6000 });
    }
  }, [locationDevicesData.Refillpoint, soilProbesData.data?.data.value])

  function validateFullAndRefillPoints(fullPoint: number, refillPoint: number) {
    if (refillPoint <= 0 || fullPoint <= 0) {
      return errorMessages[1];
    }

    if (refillPoint > fullPoint) {
      return errorMessages[0];
    }

    if (locationDevicesData.Refillpoint === null && soilProbesData.data?.data.value[0].IrrigationMethod === null) {
      return errorMessages[3];
    }

    return null;
  }

  function handleSubmit() {
    if (!isAdminOrAccountAdmin) {
      // shouldn't reach as there is a frontend check but leaving it here for bots clicking
      enqueueSnackbar("Not Allowed", { variant: 'error', persist: false, autoHideDuration: 2000 });
      return;
    }

    let requestBody: any = {
      LocationID: locationID.toString(),
    }

    let hasAutomatedFullPointChanged = locationDevicesData.AutomatedFullPoint !== automaticFullPointSelected;
    let hasFullPointChanged = locationDevicesData.Fullpoint !== fullPoint;
    let hasRefillPointChanged = locationDevicesData.Refillpoint !== refillPoint;

    if (!(hasAutomatedFullPointChanged || hasFullPointChanged || hasRefillPointChanged)) {
      enqueueSnackbar("Nothing changed", { variant: 'warning', persist: false, autoHideDuration: 2000 });
      return;
    }

    if (hasAutomatedFullPointChanged) {
      requestBody.AutomatedFullPoint = automaticFullPointSelected;

      if (automaticFullPointSelected) {
        if (hasFullPointChanged) {
          setFullPoint(locationDevicesData.Fullpoint);
          enqueueSnackbar("Setting Full point to automatic, ignoring user input.", { variant: 'warning', persist: false, autoHideDuration: 6000 });
        }

        if (locationDevicesData.StartDate === null) {
          enqueueSnackbar("Start Date not set, switching back to maunal, please contact support.", { variant: 'warning', persist: false, autoHideDuration: 6000 });
          setAutomaticFullPointSelected(false);
          return;
        }

        requestBody.Fullpoint = locationDevicesData.Fullpoint;
        requestBody.Refillpoint = locationDevicesData.Refillpoint;
      } else {
        // automatic is not selected
        const validationError = validateFullAndRefillPoints(fullPoint, refillPoint);
        if (validationError) {
          enqueueSnackbar(validationError, { variant: 'error', persist: false, autoHideDuration: 2000 });
          return;
        }

        requestBody.Refillpoint = refillPoint;
        requestBody.Fullpoint = fullPoint;
      }
    } else if (hasFullPointChanged || hasRefillPointChanged) {
      const validationError = validateFullAndRefillPoints(fullPoint, refillPoint);
      if (validationError) {
        enqueueSnackbar(validationError, { variant: 'error', persist: false, autoHideDuration: 2000 });
        return;
      }

      requestBody.Refillpoint = refillPoint;
      requestBody.Fullpoint = fullPoint;
    }

    setSaveLoading(true);
    http.put(
      `odata/Locations(${locationID})`,
      requestBody
    ).then(() => {
      setSaveLoading(false);
      enqueueSnackbar(successMessage, { variant: 'success', persist: false, autoHideDuration: 2000 });
      soilProbesData.refetch();
      _locationDevicesData.refetch();
    }
    ).catch((e) => {
      setSaveLoading(false);
      enqueueSnackbar(errorMessages[2], { variant: 'error', persist: false, autoHideDuration: 2000 })
    })
  }

  function handleClear() {
    setFullPoint(locationDevicesData.Fullpoint);
    setRefillPoint(locationDevicesData.Refillpoint);
    setAutomaticFullPointSelected(locationDevicesData.AutomatedFullPoint);
  }

  return (
    <FieldDetailPaper width='100%' id=" ">
      <FieldDetailHeader backgroundColor='#D9D7D780'>
        <div className={styles.SoilTotalHeader}>
          <div className={styles.cardHearerLeft}>
            Soil Total
            <HelpIconButton
              content={[
                {
                  title: "Soil Total",
                  content: "Soil Total expressed in mm or inches. Is the amount of water held in the soil rootzone expressed in volumetric units. This is a real-time measurement updated hourly in the app. Gofield uses the capacitance probe method to measure the soil moisture total This method uses sensors that measure the electrical capacitance of the soil, which is related to the soil moisture content. The capacitance readings are used to estimate the soil's water content. The measurements use proprietary soil calibrations which result in measurements displayed in either imperial or metric units ."
                },
                {
                  title: "Full Point",
                  content: "Soil moisture capacity refers to the maximum amount of water that a soil can hold after it has been thoroughly wetted and excess water has drained away under the force of gravity. It is also referred to as field capacity or water-holding capacity."
                },
                {
                  title: "Refill Point",
                  content: "Refers to the soil moisture level at which irrigation should be initiated to ensure that plants have access to sufficient water to support growth and development. It is also known as the irrigation trigger point. The refill point is typically determined based on the soil type, crop water requirements, and climate conditions. And can be dynamic and changed throughout the growing season."
                },
                {
                  title: "Daily Usage",
                  content: "Refers to the Volumetric water use expressed in mm or inches, extracted from the soil in the last 24hrs updated every hour."
                },
                {
                  title: "Rainfall Forecast",
                  content: "This is a ten-day forecast at a resolution of 1-13km."
                },
                {
                  title: "Irrigation Forecast",
                  content: "An irrigation forecast is a prediction of the irrigation date of a crop. Based on various factors such as weather, soil moisture levels, and crop health. The irrigation forecast is generated using an algorithm that uses forecasted weather data, NDVI satellite imagery and crop health to calculate and predict when it will reach the Refill Point."
                },
              ]}
            />
          </div>
          <div className={styles.cardHeaderRight}>
            <>
              <span className={styles.secondSpan}>{t("soilTotal-Automatic")}:</span>
              <Checkbox
                className={styles.checkbox}
                onChange={(e) => { setAutomaticFullPointSelected(e.target.checked) }}
                // "|| false" is needed here becase removing it, the checkbox doesnt populate
                checked={automaticFullPointSelected || false}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={loading || !isAdminOrAccountAdmin}
              />
              <span>{t("soilTotal-FullPoint")}:</span>
              <Tooltip title={automaticFullPointSelected ? t("soilTotal-AutomaticToolTip") : ""}>
                <TextField
                  sx={{
                    width: '8%',
                    marginRight: "5px",
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                  }}
                  size="small"
                  type="number"
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  InputLabelProps={{
                    shrink: false
                  }}
                  value={String(fullPoint)}
                  onChange={(e) => {
                    if (Number(e.target.value) >= 0)
                      setFullPoint(Number(e.target.value))
                  }}
                  label=""
                  disabled={automaticFullPointSelected}
                />
              </Tooltip>
              <span>{getUnitName(numericSystem, "mm")}</span>
              <>
                <span className={styles.secondSpan}>{t("soilTotal-RefillPoint")}:</span>
                <TextField
                  sx={{
                    width: '8%',
                    marginRight: "5px",
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                  }}
                  size="small"
                  type="number"
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  value={String(refillPoint)}
                  onChange={(e) => {
                    if (Number(e.target.value) >= 0)
                      setRefillPoint(Number(e.target.value))
                  }}
                />
                <span>{getUnitName(numericSystem, "mm")}</span>
              </>
              {
                isAdminOrAccountAdmin ?
                  <>
                    <GoannaButton
                      title={t("soilTotal-SaveButtonTitle")}
                      onClick={handleSubmit}
                      width='10%'
                      height='42px'
                      style={{
                        marginRight: "10px",
                        marginLeft: "10px",
                        marginTop: "auto"
                      }}
                      loading={saveLoading}
                      disabled={!automaticFullPointSelected ? (fullPoint === null && refillPoint === null) : false}
                    />
                    <GoannaButton
                      title={t("soilTotal-ResetButtonTitle")}
                      onClick={handleClear}
                      width='10%'
                      height='42px'
                      className={styles.clearButton}
                      disabled={saveLoading}
                    />
                  </>
                  : undefined
              }
            </>
          </div>
        </div>
      </FieldDetailHeader>
      <FieldDetailBody loading={loading} className={styles.fieldDetailBody} style={{ marginLeft: '0px', marginRight: '0px' }}>
        <ReactECharts onLegendChange={({ selected }: any) => { setLegendSelectionModel(selected) }} option={option} style={{ height: '500px', width: '100%' }} loading={loading} />
      </FieldDetailBody>
    </FieldDetailPaper>
  );
}
